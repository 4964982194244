const colors = {
  transparent: "transparent",
  current: "currentColor",
  black: "#19193b",
  white: "#ffffff",
  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
  },
  blackAlpha: {
    50: "rgba(25,25,59, 0.04)",
    100: "rgba(25,25,59, 0.06)",
    200: "rgba(25,25,59, 0.08)",
    300: "rgba(25,25,59, 0.16)",
    400: "rgba(25,25,59, 0.24)",
    500: "rgba(25,25,59, 0.36)",
    600: "rgba(25,25,59, 0.48)",
    700: "rgba(25,25,59, 0.64)",
    800: "rgba(25,25,59, 0.80)",
    900: "rgba(25,25,59, 0.92)",
  },
  green: {
    "50": "#e8fff7",
    "100": "#ccfceb",
    "200": "#a7fadc",
    "300": "#7eedc4",
    "400": "#47e5ab",
    "500": "#1cd591",
    "600": "#11b87a",
    "700": "#099663",
    "800": "#026943",
    "900": "#01462d",
  },
  brand: {
    "50": "#dbe0ff",
    "100": "#b8c1f9",
    "200": "#92a0f4",
    "300": "#7586ee",
    "400": "#586ce9",
    "500": "#4057e3",
    "600": "#364ac1",
    "700": "#2d3c9e",
    "800": "#232f7c",
    "900": "#192259",
  },
  gray: {
    "50": "#f4f9ff",
    "100": "#ecf1f8",
    "200": "#dae1e8",
    "300": "#bdc4cd",
    "400": "#979fa9",
    "500": "#6f7780",
    "600": "#4e555d",
    "700": "#363c42",
    "800": "#292d32",
    "900": "#23272b",
  },
  red: {
    "50": "#ffedf0",
    "100": "#ffccd4",
    "200": "#fc889a",
    "300": "#fa526c",
    "400": "#fa3452",
    "500": "#f71033",
    "600": "#d60d2b",
    "700": "#b50722",
    "800": "#910318",
    "900": "#6e0010",
  },
  orange: {
    "50": "#ffefeb",
    "100": "#fcd8cc",
    "200": "#f8b49f",
    "300": "#f29072",
    "400": "#f5754e",
    "500": "#f55f33",
    "600": "#eb4c1e",
    "700": "#dd3e0e",
    "800": "#c03107",
    "900": "#9c2400",
  },
  yellow: {
    "50": "#fff9e8",
    "100": "#fcf1cf",
    "200": "#fae4a2",
    "300": "#fadb7f",
    "400": "#f7ce57",
    "500": "#f2b90f",
    "600": "#dea80b",
    "700": "#c7960e",
    "800": "#b0830b",
    "900": "#926905",
  },
  teal: {
    "50": "#ebffff",
    "100": "#d1fffe",
    "200": "#a6f5f4",
    "300": "#83e5e4",
    "400": "#52c2c0",
    "500": "#319795",
    "600": "#207f7d",
    "700": "#116463",
    "800": "#074948",
    "900": "#013332",
  },
  blue: {
    "50": "#edf5ff",
    "100": "#d1e5ff",
    "200": "#96c4ff",
    "300": "#60a3fc",
    "400": "#3d8af2",
    "500": "#0f6cec",
    "600": "#0f62d6",
    "700": "#0c54b7",
    "800": "#07469c",
    "900": "#04377d",
  },
  cyan: {
    "50": "#f0fffe",
    "100": "#d6fffc",
    "200": "#a6fff7",
    "300": "#8ffff5",
    "400": "#69fff2",
    "500": "#00ffea",
    "600": "#09ebd8",
    "700": "#14ccbd",
    "800": "#0da397",
    "900": "#10897f",
  },
  purple: {
    "50": "#f5f2ff",
    "100": "#ded6ff",
    "200": "#c2b3fc",
    "300": "#a894f7",
    "400": "#8f76f5",
    "500": "#7654fa",
    "600": "#583bcc",
    "700": "#432c9e",
    "800": "#352182",
    "900": "#201259",
  },
  pink: {
    "50": "#fff0f5",
    "100": "#ffd6e5",
    "200": "#ffabc9",
    "300": "#fc86b1",
    "400": "#fc5d97",
    "500": "#f5347a",
    "600": "#d1195c",
    "700": "#ab0e46",
    "800": "#7d0631",
    "900": "#5c0524",
  },
};

export default colors;
