import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleObject } from "@chakra-ui/react";

const baseStyle: PartsStyleObject<typeof parts> = {
  header: { color: "black" },
  body: { color: "black" },
};

const overrides = {
  parts,
  baseStyle,
};

export default overrides;
