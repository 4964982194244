const shadows = {
  none: "0px 0px 0px rgba(0, 0, 0, 0)",
  outline: "0px 0px 0px 2px rgba(255, 255, 255, 0.9), 0px 0px 0px 4px #19193B",
  inner:
    "inset 0px 3px 9px rgba(0, 0, 0, 0.3),inset 0px 6px 23px rgba(25, 25, 59, 0.25)",
  xs: "0px 6px 12px -2px rgba(25, 25, 59, 0.25), 0px 3px 7px -3px rgba(0, 0, 0, 0.3)",
  sm: " 0px 6px 12px -2px rgba(25, 25, 59, 0.25), 0px 3px 7px -3px rgba(0, 0, 0, 0.3)",
  base: "0px 8px 19px -3px rgba(25, 25, 59, 0.25), 0px 5px 9px -5px rgba(0, 0, 0, 0.3)",
  md: "0px 13px 27px -5px rgba(25, 25, 59, 0.25), 0px 8px 16px -8px rgba(0, 0, 0, 0.3)",
  lg: "0px 30px 60px -12px rgba(25, 25, 59, 0.25), 0px 18px 36px -8px rgba(0, 0, 0, 0.3)",
  xl: "0px 50px 100px -20px rgba(25, 25, 59, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.3)",
  "2xl":
    "0px 80px 170px -22px rgba(25, 25, 59, 0.25), 0px 40px 90px -28px rgba(0, 0, 0, 0.3)",
  "card-sm":
    "0px 2px 5px rgba(25, 25, 59, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.12)",
  "card-md":
    "0px 7px 14px rgba(25, 25, 59, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12)",
  "card-lg":
    "0px 15px 35px rgba(25, 25, 59, 0.08), 0px 5px 15px rgba(0, 0, 0, 0.12)",
  "card-xl":
    "0px 50px 100px rgba(25, 25, 59, 0.08), 0px 15px 35px rgba(25, 25, 59, 0.08), 0px 5px 15px rgba(0, 0, 0, 0.12)",
};

export default shadows;
