/* eslint-disable @typescript-eslint/no-throw-literal */
import { redirect, type LoaderFunctionArgs } from "react-router-dom";

import {
  challengePathHashSchema,
  getChallengePathFromHash,
} from "../../helpers/hashed-challenge-path";
import type {
  ChallengeByHashQuery,
  ChallengeByHashQueryVariables,
  ProgressByChallengeHashQuery,
  ProgressByChallengeHashQueryVariables,
} from "../../generated/apollo-hooks";
import {
  ChallengeByHashDocument,
  ProgressByChallengeHashDocument,
} from "../../generated/apollo-hooks";
import { preloadQuery } from "../../apollo-client";

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const hashResults = getChallengePathFromHash(
    String(params.challengePathHash),
  );

  const isHashValid = await challengePathHashSchema.isValid(hashResults);
  if (!isHashValid) {
    throw redirect("/404");
  }

  const preloadedQuery = await Promise.all([
    hashResults.challengeType,
    preloadQuery<ChallengeByHashQuery, ChallengeByHashQueryVariables>(
      ChallengeByHashDocument,
      {
        variables: { challengePathHash: String(params.challengePathHash) },
        fetchPolicy: "cache-first",
      },
    ),
    preloadQuery<
      ProgressByChallengeHashQuery,
      ProgressByChallengeHashQueryVariables
    >(ProgressByChallengeHashDocument, {
      variables: { hash: String(params.challengePathHash) },
      fetchPolicy: "network-only",
    }),
  ]);
  return preloadedQuery;
};
