import { extendTheme } from "@chakra-ui/react";

import styles from "./styles";
import components from "./components";
import foundations from "./foundations";
import { withProseTheme } from "../prose/theme";

const theme = extendTheme(
  {
    config: {
      cssVarPrefix: "",
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    direction: "ltr",
    ...foundations,
    styles,
    components,
  },
  withProseTheme(),
);

export { theme };
