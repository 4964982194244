import { SystemStyleObject } from "@chakra-ui/react";

const baseStyle: SystemStyleObject = {
  fontFamily: "heading",
  fontWeight: "bold",
  color: "black",
};

const overrides = {
  baseStyle,
};

export default overrides;
