import { ChallengeType } from "../generated/apollo-hooks";

/* APP */
export const IS_PROD = import.meta.env.PROD;
export const API_URL = String(import.meta.env.REACT_APP_API_URL);
export const WEB_APP_URL = String(import.meta.env.REACT_APP_WEB_APP_URL);
export const WEB_SITE_URL = String(import.meta.env.REACT_APP_WEB_SITE_URL);
export const APP_VERSION = __APP_VERSION__;
export const CHALLENGE_TYPES: ChallengeType[] = [
  "LetterLesson",
  "GrammarLesson",
  "VocabularyLesson",
  "ReadingLesson",
  "Quiz",
];

/* IMAGE FILE LIMITS */
export const USER_MAX_IMAGE_SIZE_BYTES = 5_000_000;
export const ALLOWED_IMAGE_TYPES = ["image/png", "image/jpg", "image/jpeg"];

/* STUDIO CONSTANTS */
export const ASSETS_HOST_URL = import.meta.env.REACT_APP_ASSETS_HOST_URL;
