import Button from "./button";
import CloseButton from "./close-button";
import Input from "./input";
import Textarea from "./textarea";
import Form from "./form";
import FormLabel from "./form-label";
import FormError from "./form-error";
import Modal from "./modal";
import Heading from "./heading";
import List from "./list";
import Logo from "./logo";
import Tabs from "./tabs";
import Tooltip from "./tooltip";
import Alert from "./alert";

const components = {
  Button,
  CloseButton,
  Input,
  Textarea,
  Form,
  FormLabel,
  FormError,
  Modal,
  Heading,
  List,
  Logo,
  Tabs,
  Tooltip,
  Alert,
};

export default components;
