import { theme } from "@chakra-ui/theme";
import { SystemStyleObject } from "@chakra-ui/react";

// Get default button sizes styles from Chakra UI default theme
const defaultButtonSizes = theme.components.Button.sizes!;

/* Style object for base or default style */
const baseStyle: SystemStyleObject = {
  fontWeight: 500,
  borderRadius: "full",
  touchAction: "none",
  _active: { bg: "" },
  _hover: { bg: "" },
};

/* Styles for different sizes "xs", "sm", "md", "lg" */
const size: Record<string, SystemStyleObject> = {
  lg: {
    ...defaultButtonSizes.lg,
    px: 6,
  },
  md: {
    ...defaultButtonSizes.md,
    px: 5,
  },
  sm: {
    ...defaultButtonSizes.sm,
    px: 4,
  },
  xs: {
    ...defaultButtonSizes.xs,
    px: 3,
  },
};

const sizes: Record<string, SystemStyleObject> = {
  lg: { ...size.lg },
  md: { ...size.md },
  sm: { ...size.sm },
  xs: { ...size.xs },
  "for-icon:lg": {
    ...size.lg,
    fontSize: "3xl",
  },
  "for-icon:md": {
    ...size.md,
    fontSize: "xl",
  },
  "for-icon:sm": {
    ...size.sm,
    fontSize: "lg",
  },
  "for-icon:xs": {
    ...size.xs,
    fontSize: "md",
  },
};

/* Styles for different visual variants "outline", "solid", "ghost", "link" */
const solid: SystemStyleObject = { ...baseStyle };

const outline: SystemStyleObject = { ...baseStyle, border: "2px" };

const ghost: SystemStyleObject = {
  ...baseStyle,
  color: "black",
  _hover: { bg: "gray.100" },
  _active: { bg: "gray.100" },
  _focusVisible: { bg: "gray.100" },
};

const link: SystemStyleObject = { color: "black" };

const variants = {
  solid,
  outline,
  ghost,
  link,
};

/* Default values for `size` and `variant` */
const defaultProps = {
  size: "md",
  variant: "solid",
  colorScheme: "brand",
};

const overrides = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default overrides;
