export interface YupGraphqlError<T> {
  path: keyof T;
  message: string;
}

/**
 * Error code enums
 */
export const graphQLApiErrorCode = {
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  FORBIDDEN: "FORBIDDEN",
  BAD_USER_INPUT: "BAD_USER_INPUT",
  NOT_FOUND: "NOT_FOUND",
  PREMIUM_NEEDED: "PREMIUM_NEEDED",
} as const;

export type GraphQLApiErrorCode = keyof typeof graphQLApiErrorCode;

export type GraphQLApiErrorUserInput = { field: string; message: string }[];
