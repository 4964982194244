import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from "@chakra-ui/react";
import { getColor } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    fontWeight: 500,
    color: "black",
  },
  addon: {
    color: "black",
    border: "none",
  },
};

const size: Record<string, SystemStyleObject> = {
  lg: {
    fontSize: "md",
    px: 4,
    h: 12,
    borderRadius: "5px",
  },
  md: {
    fontSize: "sm",
    px: 4,
    h: "44px",
    borderRadius: "5px",
  },
  sm: {
    fontSize: "sm",
    px: 3,
    h: 8,
    borderRadius: "base",
  },
  xs: {
    fontSize: "xs",
    px: 2,
    h: 6,
    borderRadius: "base",
  },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
};

const outline: PartsStyleFunction<typeof parts> = (props) => {
  const { theme, focusBorderColor, errorBorderColor } = props;
  const fc = focusBorderColor || getColor(theme, "brand.500");
  const ec = errorBorderColor || getColor(theme, "red.500");

  return {
    field: {
      ...baseStyle.field,
      border: "2px",
      _focusVisible: {
        zIndex: 1,
        borderColor: fc,
        boxShadow: `0 0 0 0.5px ${getColor(theme, fc as string)}`,
      },
      _invalid: {
        borderColor: ec,
        boxShadow: `0 0 0 0.5px ${getColor(theme, ec as string)}`,
      },
    },
    addon: {
      ...baseStyle.addon,
    },
  };
};

const variants = {
  outline,
};

const defaultProps = {
  size: "md",
  variant: "outline",
};

const overrides = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default overrides;
