import { SystemStyleObject } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/theme-tools";

const $bg = cssVar("tooltip-bg");

const baseStyle: SystemStyleObject = {
  px: 2,
  py: 2,
  color: "white",
  [$bg.variable]: "black",
  bg: [$bg.reference],
  outline: "1.5px",
};

const overrides = {
  baseStyle,
};

export default overrides;
