import colors from "./colors";
import typography from "./typography";
import shadows from "./shadows";
import radii from "./radius";
import borders from "./borders";
import sizes from "./sizes";

const foundations = {
  colors,
  ...typography,
  shadows,
  radii,
  borders,
  sizes,
};

export default foundations;
