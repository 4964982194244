import { ASSETS_HOST_URL, IS_PROD } from "../constants";

/**
 * Transfer uploaded file url to assets cdn url in production or return original url in development
 * Example: storage.googleapis.com/bucket-name/file-name -> assets.natanada.com/file-name
 */
export const getCdnUploadUrl = (uploadUrl = ""): string => {
  if (!IS_PROD) return uploadUrl;
  try {
    const parsedUploadUrl = new URL(uploadUrl);
    const { pathname } = parsedUploadUrl;
    const [uploadType, uploadName] = pathname.split("/").slice(2);

    const cdnUrl = `${ASSETS_HOST_URL}/${uploadType}/${uploadName}`;
    return cdnUrl;
  } catch {
    return "";
  }
};

export const getRedirectToURI = (searchParams: URLSearchParams) => {
  const redirectToParam = searchParams.get("redirectTo");
  if (!redirectToParam) {
    return null;
  }
  try {
    const redirectUrl = new URL(redirectToParam);
    return redirectUrl.pathname + redirectUrl.search;
  } catch {
    return null;
  }
};
