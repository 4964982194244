import { formAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleObject } from "@chakra-ui/react";

const baseStyle: PartsStyleObject<typeof parts> = {
  requiredIndicator: {
    marginStart: 1,
    color: "red.500",
  },
  helperText: {
    mt: 2,
    color: "gray.500",
    lineHeight: "normal",
    fontSize: "xs",
  },
};

const overrides = {
  parts,
  baseStyle,
};

export default overrides;
