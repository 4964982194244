/* eslint-disable @typescript-eslint/no-throw-literal */
import { type LoaderFunctionArgs } from "react-router-dom";

import type {
  CollectionReviewByIdQuery,
  CollectionReviewByIdQueryVariables,
} from "../../generated/apollo-hooks";
import { CollectionReviewByIdDocument } from "../../generated/apollo-hooks";
import { preloadQuery } from "../../apollo-client";

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = ({ params }: LoaderFunctionArgs) => {
  const preloadedQuery = preloadQuery<
    CollectionReviewByIdQuery,
    CollectionReviewByIdQueryVariables
  >(CollectionReviewByIdDocument, {
    variables: {
      input: {
        collectionId: params.idOrCollectionId,
        id: params.idOrCollectionId,
      },
    },
  });

  return preloadedQuery;
};
