import { preloadQuery } from "../../apollo-client";
import {
  ResumeCurrentProgressDocument,
  LearnCollectionsDocument,
  StreakOverviewDocument,
} from "../../generated/apollo-hooks";
import type {
  LearnCollectionsQuery,
  LearnCollectionsQueryVariables,
  ResumeCurrentProgressQuery,
  ResumeCurrentProgressQueryVariables,
  StreakOverviewQuery,
  StreakOverviewQueryVariables,
} from "../../generated/apollo-hooks";

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = async () => {
  const preLoadedQueries = await Promise.all([
    preloadQuery<
      ResumeCurrentProgressQuery,
      ResumeCurrentProgressQueryVariables
    >(ResumeCurrentProgressDocument, {
      fetchPolicy: "cache-and-network",
    }),
    preloadQuery<LearnCollectionsQuery, LearnCollectionsQueryVariables>(
      LearnCollectionsDocument,
      {
        fetchPolicy: "cache-and-network",
      },
    ),
    preloadQuery<StreakOverviewQuery, StreakOverviewQueryVariables>(
      StreakOverviewDocument,
      {
        fetchPolicy: "cache-and-network",
      },
    ),
  ]);
  return preLoadedQueries;
};
