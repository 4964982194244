/* eslint-disable @typescript-eslint/no-throw-literal */
import { redirect } from "react-router-dom";

import { client } from "../apollo-client";
import { CurrentUserDocument } from "../generated/apollo-hooks";
import type { CurrentUserQuery } from "../generated/apollo-hooks";
import { getRedirectToURI } from "../helpers/url";

export const getCurrentUser = async () => {
  const { data, error } = await client.query<CurrentUserQuery>({
    query: CurrentUserDocument,
  });

  // Throw error if it exists
  if (error) {
    throw error;
  }

  return data.currentUser;
};

/**
 * Protect pages or fallback to a certain page when a user is not signed in
 */
export const requireUser = async (
  options: {
    fallbackTo?: string;
    requireOnboarding?: boolean;
    allowRedirection?: boolean;
  },
  request: Request,
) => {
  const {
    fallbackTo = "/sign-in",
    requireOnboarding = false,
    allowRedirection = false,
  } = options;
  const currentUser = await getCurrentUser();
  let fallbackToWithRedirect = fallbackTo;
  if (allowRedirection) {
    fallbackToWithRedirect = `${fallbackTo}?redirectTo=${request?.url}`;
  }

  if (!currentUser) {
    throw redirect(fallbackToWithRedirect);
  }

  // Force onboarding process if user didn't complete it yet
  if (requireOnboarding && currentUser?.onboardingState !== "COMPLETED_03") {
    throw redirect("/onboarding");
  }
};

/**
 * Render the page when the user is not logged in or fallback to a certain page
 */
export const requirePublic = async (
  options: { fallbackTo?: string; allowRedirection?: boolean },
  request: Request,
) => {
  const { fallbackTo = "/", allowRedirection = false } = options;
  const currentUser = await getCurrentUser();
  let fallbackToWithRedirect = fallbackTo;
  if (allowRedirection) {
    const redirectUrl = new URL(request.url);
    fallbackToWithRedirect =
      getRedirectToURI(redirectUrl.searchParams) ?? fallbackTo;
  }

  if (currentUser) {
    throw redirect(fallbackToWithRedirect);
  }
};

/**
 * Protect admin or editor pages
 */
export const requireAdminOrEditor = async () => {
  const currentUser = await getCurrentUser();
  const isAdminOrEditor =
    currentUser && ["ADMIN", "EDITOR"].includes(currentUser?.role);

  if (!isAdminOrEditor) {
    throw redirect("/sign-in");
  }
};
