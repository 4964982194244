import * as React from "react";
import { RouterProvider } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@natanada/ui/theme";
import { toastOptions } from "@natanada/ui";
import { ApolloProvider } from "@apollo/client";

import router from "./router";
import { FullWidthSpinner } from "./components/spinner";
import { PostHogProvider, posthog } from "./lib/posthog";
import { client } from "./apollo-client";
import { AuthProvider } from "./authentication/context";
import { useChunkPreloadErrorHandling } from "./hooks/use-vite-preload";

const App: React.FC = () => {
  useChunkPreloadErrorHandling();

  return (
    <PostHogProvider client={posthog}>
      <ChakraProvider theme={theme} toastOptions={toastOptions}>
        <ApolloProvider client={client}>
          <AuthProvider>
            <RouterProvider
              router={router}
              fallbackElement={<FullWidthSpinner />}
              future={{ v7_startTransition: true }}
            />
          </AuthProvider>
        </ApolloProvider>
      </ChakraProvider>
    </PostHogProvider>
  );
};

export default App;
