import { cache } from "./cache";
import { CurrentUserDocument } from "../generated/apollo-hooks";
import type { CurrentUserQuery } from "../generated/apollo-hooks";
import { authStore } from "../authentication/context";

// Watch for user updates in the cache and update the auth store
cache.watch<CurrentUserQuery>({
  query: CurrentUserDocument,
  callback: ({ result, complete }) => {
    if (complete) {
      authStore.getState().updateCurrentUser(result?.currentUser);
    }
  },
  optimistic: true,
});
