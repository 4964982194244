import { ApolloClient, createQueryPreloader } from "@apollo/client";
// eslint-disable-next-line import/extensions
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import { API_URL } from "../constants";
import { cache } from "./cache";

// File upload link
const link = createUploadLink({
  uri: `${API_URL}/graphql`,
  credentials: "include",
  headers: {
    // This request header is required for file uploads
    // https://www.apollographql.com/docs/apollo-server/security/cors/#preventing-cross-site-request-forgery-csrf
    "Apollo-Require-Preflight": "true",
  },
});

export const client = new ApolloClient({
  link,
  cache,
});

export const preloadQuery = createQueryPreloader(client);
