import * as React from "react";

import {
  SpinnerWrapper,
  SpinnerStyle,
  FullWidthWrapper,
  CardSpinnerStyle,
  SPINNER_ANIMATION_NAME,
} from "./style";
import { useSynchronizedAnimation } from "../../hooks/use-synchronized-animation";

type SpinnerSize = "small" | "normal" | "large";

interface SpinnerProps {
  value?: number;
  size?: SpinnerSize;
  color?: string;
}

// check http://stackoverflow.com/a/18473154/3124288 for calculating arc path
const R = 45;
const SPINNER_TRACK = `m 50,50 m 0,-${R} a ${R},${R} 0 1 1 0,${
  R * 2
} a ${R},${R} 0 1 1 0,-${R * 2}`;

const PATH_LENGTH = 280;

const STROKE_WIDTH = 4;
const MIN_STROKE_WIDTH = 16;

const SIZE_SMALL = 20;
const SIZE_STANDARD = 35;
const SIZE_LARGE = 50;

const getViewBox = (strokeWidth: number) => {
  const radius = R + strokeWidth / 2;
  const viewBoxX = 50 - radius;
  const viewBoxWidth = radius * 2;
  return `${viewBoxX} ${viewBoxX} ${viewBoxWidth} ${viewBoxWidth}`;
};

const getSize = (size: SpinnerSize | number) => {
  if (typeof size === "string") {
    if (size === "small") {
      return SIZE_SMALL;
    }
    if (size === "large") {
      return SIZE_LARGE;
    }
    return SIZE_STANDARD;
  }
  return Math.max(SIZE_SMALL, size);
};

const Spinner: React.FC<SpinnerProps> = ({
  color = "#395aed",
  size: s = 35,
  value = null,
}) => {
  const ref = useSynchronizedAnimation<HTMLDivElement>(SPINNER_ANIMATION_NAME);

  const size = getSize(s);

  // keep spinner track width consistent at all sizes (down to about 20px).
  const strokeWidth = Math.min(
    MIN_STROKE_WIDTH,
    (STROKE_WIDTH * SIZE_LARGE) / size,
  );

  const strokeOffset =
    PATH_LENGTH -
    PATH_LENGTH * (value == null ? 0.25 : Math.min(Math.max(value, 0), 1));

  return (
    <SpinnerWrapper>
      <SpinnerStyle ref={ref} color={color}>
        <svg
          width={size}
          height={size}
          strokeWidth={strokeWidth}
          viewBox={getViewBox(strokeWidth)}
        >
          <path className="spinner-track" d={SPINNER_TRACK} />
          <path
            className="spinner-head"
            d={SPINNER_TRACK}
            pathLength={PATH_LENGTH}
            strokeDasharray={`${PATH_LENGTH} ${PATH_LENGTH}`}
            strokeDashoffset={strokeOffset}
          />
        </svg>
      </SpinnerStyle>
    </SpinnerWrapper>
  );
};

export const FullWidthSpinner: React.FC<SpinnerProps> = (props) => (
  <FullWidthWrapper>
    <Spinner {...props} />
  </FullWidthWrapper>
);

export const CardSpinner: React.FC<SpinnerProps & { alt?: string }> = ({
  alt = "Loading...",
  ...rest
}) => (
  <CardSpinnerStyle>
    <Spinner {...rest} />
    <h2>{alt}</h2>
  </CardSpinnerStyle>
);

export default Spinner;
