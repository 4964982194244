import { InMemoryCache } from "@apollo/client";

import introspectionResult from "../generated/introspection";

// In memory cache init
export const cache = new InMemoryCache({
  // Map the name of an interface or union type
  // to the type that implement or belong to it
  // https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
  possibleTypes: introspectionResult.possibleTypes,
  // Defining the primary key fields for the "User" type
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
  typePolicies: {},
});
