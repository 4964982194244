import { withProse } from "@nikolovlazar/chakra-ui-prose";

export const withProseTheme = () =>
  withProse({
    baseStyle: {
      "& :first-child": {
        marginTop: 0,
      },
      "h1,h2,h3,h4,h5,h6": {
        scrollMarginTop: 8,
      },
      a: { color: "brand.500" },
      "h1>a, h2>a, h3>a, h4>a, h5>a, h6>a": {
        color: "inherit",
        fontWeight: "inherit",
        _hover: {
          textDecor: "none",
        },
      },
      "ol>li::marker": {
        color: "inherit",
      },
      "ul>li::marker": {
        color: "inherit",
      },
      "details>summary": {
        cursor: "pointer",
        userSelect: "none",
        "::marker": {
          color: "brand.500",
        },
      },
      blockquote: {
        borderInlineStart: "none",
        fontStyle: "initial",
        "& p:last-of-type": {
          mb: "0",
        },
      },
    },
  });
