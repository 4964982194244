import * as React from "react";

let stashedTime: number | null | CSSNumberish;

export const useSynchronizedAnimation = <ElementRef extends HTMLElement>(
  animationName: string,
) => {
  const ref = React.useRef<ElementRef>(null);

  React.useLayoutEffect(() => {
    const animations = (document.getAnimations() as CSSAnimation[]).filter(
      (animation) => animation.animationName === animationName,
    );

    const currentAnimation = animations.find(
      (animation) =>
        (animation.effect as KeyframeEffect).target === ref.current,
    );

    if (currentAnimation && currentAnimation === animations[0] && stashedTime) {
      currentAnimation.currentTime = stashedTime;
    }

    if (currentAnimation && currentAnimation !== animations[0]) {
      currentAnimation.currentTime = animations?.[0]?.currentTime as number;
    }

    return () => {
      if (currentAnimation && currentAnimation === animations[0]) {
        stashedTime = currentAnimation.currentTime;
      }
    };
  }, [animationName]);

  return ref;
};
