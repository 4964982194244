import * as React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ApolloError } from "@apollo/client";
import {
  isRouteErrorResponse,
  Navigate,
  useRouteError,
} from "react-router-dom";
import { Logo } from "@natanada/ui";

import { RiErrorWarningLine } from "react-icons/ri";

import PageTitle from "../components/page-title";
import { Sentry } from "../lib/sentry";
import { graphQLApiErrorCode as codes } from "../types/error";

export const ErrorPage: React.FC<{ isRoot?: boolean }> = (props) => {
  const { isRoot } = props;
  const error = useRouteError() as Error | ApolloError;
  const [feedback] = React.useState(() => Sentry.getFeedback());

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const handleFeedbackClick = async () => {
    const feedbackWidget = await feedback?.createForm({
      formTitle: "Feedback",
      submitButtonLabel: "Send",
      successMessageText: "Thank you for your feedback!",
    });
    feedbackWidget?.appendToDom();
    feedbackWidget?.open();
  };

  if (error instanceof ApolloError) {
    /**
     * Handle when a mutation or a query returns a network error
     * https://www.apollographql.com/docs/react/data/error-handling/#network-errors
     * Capture the error with Sentry and return a fallback
     */
    if (error.networkError) {
      Sentry.withScope((scope) => {
        scope.setTag("[Network error]", true);
        Sentry.captureException(error);
      });
    }
    /**
     * Handle graphql error with "INTERNAL_SERVER_ERROR" code
     * https://www.apollographql.com/docs/react/data/error-handling/#graphql-errors
     */
    if (
      error.graphQLErrors.some(
        (e) => e.extensions?.code === codes.INTERNAL_SERVER_ERROR,
      )
    ) {
      Sentry.withScope((scope) => {
        scope.setTag("code", codes.INTERNAL_SERVER_ERROR);
        Sentry.captureException(error);
      });
    }
    /**
     * Redirect to sign out page when a mutation or a query returns "UNAUTHENTICATED" error
     */
    if (
      error.graphQLErrors.some(
        (e) => e.extensions?.code === codes.UNAUTHENTICATED,
      )
    ) {
      return <Navigate to="/sign-out" />;
    }
    /**
     * Redirect to 404 page item not found or a user is forbidden to perform an action
     */
    if (
      error.graphQLErrors.some(
        (e) =>
          e.extensions?.code === codes.FORBIDDEN ||
          e.extensions?.code === codes.NOT_FOUND,
      )
    ) {
      return <Navigate to="/404" replace />;
    }
    /**
     * Redirect to upgrade page when a user try to access premium content
     */
    if (
      error.graphQLErrors.some(
        (e) => e.extensions?.code === codes.PREMIUM_NEEDED,
      )
    ) {
      return <Navigate to="/settings/subscription" />;
    }
  }

  /**
   * Redirect to 404 page if the route is not found
   */
  if (isRouteErrorResponse(error) && error.status === 404) {
    return <Navigate to="/404" replace />;
  }

  /**
   * Capture the error with Sentry
   */
  Sentry.withScope(() => {
    Sentry.captureException(error);
  });

  const Wrapper = isRoot ? "main" : React.Fragment;
  return (
    <Wrapper>
      <Container centerContent maxW="container.xl" py={4}>
        <PageTitle>Error - Something went wrong</PageTitle>
        <Box
          w="full"
          maxW="lg"
          p={8}
          my={32}
          bg="white"
          rounded="md"
          shadow="card-sm"
        >
          {isRoot ? (
            <Flex mb={8}>
              <Logo />
            </Flex>
          ) : null}
          <VStack
            spacing={2}
            role="alert"
            alignItems="center"
            justifyContent="center"
          >
            <Icon as={RiErrorWarningLine} fontSize="4xl" />
            <Heading
              as="h1"
              fontSize="md"
              lineHeight="short"
              textAlign="center"
            >
              Oops! Something went wrong
            </Heading>
            {error?.message ? (
              <Text textAlign="center" color="gray.500">
                {error?.message}
              </Text>
            ) : null}
          </VStack>
          <HStack justify="center" mt={8} wrap="wrap">
            <Button
              variant="outline"
              w={{ base: "full", sm: "auto" }}
              onClick={handleRefreshPage}
            >
              Reload Page
            </Button>
            {feedback ? (
              <Button
                variant="outline"
                colorScheme="gray"
                w={{ base: "full", sm: "auto" }}
                onClick={() => {
                  handleFeedbackClick();
                }}
              >
                Send feedback
              </Button>
            ) : null}
          </HStack>
        </Box>
      </Container>
    </Wrapper>
  );
};
