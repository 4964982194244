import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const styleHelpers = createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = styleHelpers.definePartsStyle({
  // TODO Workaround for customizing toast styles (remove when toasts can be themeable)
  container: {
    "&[id^='toast-']": {
      "div[id*='-description']": {
        fontSize: "sm",
      },
      "button[aria-label='Close']": {
        _hover: {
          bg: "blackAlpha.100",
        },
        svg: {
          fontSize: "60%",
        },
      },
    },
  },
});

const overrides = styleHelpers.defineMultiStyleConfig({ baseStyle });

export default overrides;
