import * as React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { type CurrentUserQuery } from "../../generated/apollo-hooks";
import { APP_VERSION } from "../../constants";

/**
 * Wrap createBrowserRouter with Sentry
 */
export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

/**
 * Initializing Sentry with default config
 */
export const initializeSentry = () =>
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    release: `web@${APP_VERSION}`,
    environment: import.meta.env.MODE,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [
      Sentry.feedbackIntegration({
        autoInject: false,
        showBranding: false,
        enableScreenshot: false,
        isEmailRequired: true,
        colorScheme: "light",
      }),
      Sentry.replayIntegration({ maskAllText: false }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
  });

/**
 * Identify the user
 */
export const sentryIdentifyUser = (
  currentUser: CurrentUserQuery["currentUser"],
) =>
  Sentry.setUser({
    fullName: currentUser?.fullName,
    email: currentUser?.email,
    role: currentUser?.role,
    plan: currentUser?.subscription.plan,
  });

/**
 * Clear the currently set user
 */
export const sentryClearUser = () => Sentry.setUser(null);

/**
 * Export sentry error boundary component
 */
export const SentryErrorBoundary = Sentry.ErrorBoundary;

export * as Sentry from "@sentry/react";
