import * as yup from "yup";

import { btoa, atob } from "../lib/base64";
import { ChallengeType } from "../generated/apollo-hooks";
import { CHALLENGE_TYPES } from "../constants";

interface ChallengePath {
  challengeType: ChallengeType;
  id: string;
}

interface HashResult {
  challengeType: ChallengeType | null;
  id: string;
}

type GetHashedChallengePath = (args: ChallengePath) => string;

export const challengePathHashSchema = yup.object().shape({
  challengeType: yup
    .string()
    .oneOf(CHALLENGE_TYPES)
    .required("Challenge type is required"),
  id: yup.string().uuid().required("Challenge id is required"),
});

export const getHashedChallengePath: GetHashedChallengePath = ({
  challengeType,
  id,
}) => {
  const challengePathString = `${challengeType}#${id}`;
  return btoa(challengePathString);
};

export const getChallengePathFromHash = (hash: string): HashResult => {
  // Decode the hash and split the string in "-" character
  const [rawChallengeType, rawId] = atob(hash).split("#");

  // Challenge type.
  let challengeType: ChallengeType | null = null;

  // Check if it is exists and matches challenge type
  if (
    rawChallengeType &&
    CHALLENGE_TYPES.includes(rawChallengeType as ChallengeType)
  ) {
    challengeType = rawChallengeType as ChallengeType;
  }

  // Challenge id
  const id = rawId ?? "";

  return {
    challengeType,
    id,
  };
};
