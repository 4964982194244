
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Challenge": [
      "GrammarLesson",
      "LetterLesson",
      "Quiz",
      "ReadingLesson",
      "VocabularyLesson"
    ],
    "InputError": [
      "CollectionError",
      "CollectionUpdateStatusError",
      "LessonError",
      "LevelError"
    ]
  }
};
      export default result;
    