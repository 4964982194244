import * as React from "react";

import { Sentry } from "../lib/sentry";

export function useChunkPreloadErrorHandling() {
  React.useEffect(() => {
    const onChunkError = (event: VitePreloadErrorEvent) => {
      // event.preventDefault();
      console.log("Missing chunks, reloading page...");
      Sentry.captureException(event.payload);
      window.location.reload();
    };

    window.addEventListener("vite:preloadError", onChunkError);
    return () => {
      window.removeEventListener("vite:preloadError", onChunkError);
    };
  }, []);
}
