import type { ToastProviderProps } from "@chakra-ui/react";

const getDirectionAndFactor = (position: string) => {
  const dir =
    position.includes("top") || position.includes("bottom") ? "y" : "x";
  let factor = ["top-right", "bottom-right"].includes(position) ? 1 : -1;
  if (position === "bottom") factor = 1;
  return { dir, factor };
};

export const toastOptions: ToastProviderProps = {
  defaultOptions: {
    isClosable: true,
    position: "bottom",
  },
  motionVariants: {
    initial: (props: { position: string }) => {
      const { position } = props;
      const { dir, factor } = getDirectionAndFactor(position);
      return {
        opacity: 0,
        [dir]: factor * 50,
      };
    },
    animate: {
      opacity: 1,
      y: 0,
      x: 0,
      scale: 1,
      transition: { type: "spring", stiffness: 700, damping: 80 },
    },
    exit: (props: { position: string }) => {
      const { position } = props;
      const { dir, factor } = getDirectionAndFactor(position);
      return {
        opacity: 0,
        scale: 0.85,
        [dir]: factor * 50,
        transition: { duration: 0.4 },
      };
    },
  },
};
