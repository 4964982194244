import { SystemStyleObject, SystemStyleFunction } from "@chakra-ui/react";

import Input from "./input";

const baseStyle: SystemStyleObject = {
  ...Input.baseStyle.field,
  paddingY: "8px",
  minHeight: "80px",
  lineHeight: "short",
  verticalAlign: "top",
};

const sizes: Record<string, SystemStyleObject> = {
  xs: { ...Input.sizes?.xs?.field },
  sm: { ...Input.sizes?.sm?.field },
  md: { ...Input.sizes?.md?.field },
  lg: { ...Input.sizes?.lg?.field },
};

const outline: SystemStyleFunction = (props) => {
  return { ...Input.variants.outline(props).field };
};

const variants = {
  outline,
};

const defaultProps = {
  size: "md",
  variant: "outline",
};

const overrides = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default overrides;
