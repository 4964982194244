import type { LoaderFunctionArgs } from "react-router-dom";

import { preloadQuery } from "../../apollo-client";
import { LevelDocument } from "../../generated/apollo-hooks";
import type {
  LevelQuery,
  LevelQueryVariables,
} from "../../generated/apollo-hooks";

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return preloadQuery<LevelQuery, LevelQueryVariables>(LevelDocument, {
    variables: { where: { id: params.levelId } },
    fetchPolicy: "cache-and-network",
  });
};
