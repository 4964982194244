import { formErrorAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleObject } from "@chakra-ui/react";

const baseStyle: PartsStyleObject<typeof parts> = {
  text: { color: "red.500", mt: 2, fontSize: "xs" },
  icon: { fontSize: "md", marginEnd: "0.5em", color: "red.500" },
};

const overrides = {
  parts,
  baseStyle,
};

export default overrides;
