import { listAnatomy as parts } from "@chakra-ui/anatomy";
import { PartsStyleObject } from "@chakra-ui/react";

const baseStyle: PartsStyleObject<typeof parts> = {
  container: { color: "black" },
  item: { color: "black" },
  icon: {
    marginEnd: "0.5rem",
    display: "inline",
    verticalAlign: "text-bottom",
  },
};

const overrides = {
  parts,
  baseStyle,
};

export default overrides;
