import { Buffer } from "buffer/";

/**
 * Encode a string in base-64
 * @param str string to be encoded
 */

export const btoa = (str: string) => {
  return Buffer.from(str).toString("base64");
};

/**
 * Decode a base-64 encoded string
 * @param encodedStr string which has been encoded by the btoa() method
 */
export const atob = (encodedStr: string) => {
  return Buffer.from(encodedStr, "base64").toString("binary");
};
