import * as React from "react";
import { Helmet } from "react-helmet";

export interface PageTitleProps {
  children: string | string[];
}

const PageTitle: React.FC<PageTitleProps> = ({ children }) => (
  <Helmet defaultTitle="Natanada" titleTemplate="%s | Natanada">
    <title>{children}</title>
  </Helmet>
);

export default PageTitle;
