import * as React from "react";
import ReactDOM from "react-dom/client";

import "@natanada/ui/fonts";

import App from "./app";
import reportWebVitals from "./report-web-vitals";
import { initializeSentry } from "./lib/sentry";
import { initPosthog } from "./lib/posthog";

/**
 * Initialize Sentry React SDK
 */
initializeSentry();

/**
 * Initialize Posthog
 */
if (import.meta.env.PROD) {
  initPosthog();
}

ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
