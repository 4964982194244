import { SystemStyleObject } from "@chakra-ui/react";

const baseStyle: SystemStyleObject = {
  fontSize: "sm",
  color: "black",
  marginEnd: 3,
  mb: 2,
  fontWeight: "medium",
  transitionProperty: "common",
  transitionDuration: "normal",
  opacity: 1,
  _disabled: {
    opacity: 1,
  },
};

const overrides = {
  baseStyle,
};

export default overrides;
