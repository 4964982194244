import { SystemStyleFunction, SystemStyleObject } from "@chakra-ui/react";

const baseStyle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  return {
    color: `${c}.500`,
    fill: "currentColor",
    w: "auto",
  };
};

const sizes: Record<string, SystemStyleObject> = {
  lg: {
    h: 10,
  },
  md: {
    h: 7,
  },
  sm: { h: 5 },
};

const defaultProps = {
  size: "md",
  variant: "complete",
  colorScheme: "brand",
};

const overrides = {
  baseStyle,
  sizes,
  defaultProps,
};

export default overrides;
