import { SystemStyleObject } from "@chakra-ui/react";

/* Style object for base or default style */
const baseStyle: SystemStyleObject = {
  borderRadius: "sm",
  touchAction: "none",
  _hover: { bg: "gray.100" },
  _active: { bg: "gray.100" },
  _focusVisible: {
    bg: "gray.100",
  },
};

/* Styles for different sizes "sm", "md", "lg" */
const sizes: Record<string, SystemStyleObject> = {
  lg: {
    w: "42px",
    h: "42px",
    fontSize: "32px",
  },
  md: {
    w: "32px",
    h: "32px",
    fontSize: "24px",
  },
  sm: {
    w: "24px",
    h: "24px",
    fontSize: "16px",
  },
};

/* Default values for `size` and `variant` */
const defaultProps = {
  size: "md",
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
